<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div class="container mt-5">
      <div v-if="!staff" id="step1" class="p-3">
        <b-card>
          <div class="text-center mt-2">
            <h1 class="h5 font-weight-bold">啟用帳號</h1>
          </div>
          <div class="text-center mt-3 mb-5">
            <h5>請填寫您的資料</h5>
          </div>
          <validation-error-alert
            :errors="validationErrors"
            v-if="validationErrors"
          ></validation-error-alert>
          <div class="form-group">
            <label>員工編號</label>
            <input
              type="text"
              v-model="employeeCode"
              class="form-control"
              placeholder="請輸入您的員工編號"
            />
          </div>
          <div class="form-group">
            <label>E-mail</label>
            <div class="flex">
              <input
                type="text"
                v-model="email"
                class="form-control col-6"
                placeholder="請輸入您的 E-mail"
              />
              <div class="email-input-extra col-6">
                <span>@chailease.com.tw</span>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <b-button
              size="lg"
              variant="outline-dark mr-3 w-100"
              @click="cancel"
              :disabled="buttonLoading"
              >取消</b-button
            >
            <b-button
              size="lg"
              variant="primary w-100"
              @click="submit"
              :disabled="buttonLoading"
              >送出</b-button
            >
          </div>
        </b-card>
      </div>
      <div v-if="staff" id="step2" class="p-3">
        <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
          <div class="text-center font-weight-bolder">
            <h3>{{ staff.name }}</h3>
            <p class="text-muted">
              {{ staff.job_level }}<br>
              {{ staff.service_unit }}
            </p>
          </div>
        </b-card>

        <div v-if="staff" class="text-center mt-3">
          <h5>帳號綁定成功</h5>
        </div>
        <div class="text-center">
          <b-button @click="cancel" variant="success" size="lg" class="mb-1 mt-3">
            回官方帳號
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import liff from "@line/liff";
import axios from "axios";

export default {
  data() {
    return {
      initData: null,
      displayLoading: true,
      lineData: null,
      buttonLoading: false,
      organization: null,
      validationErrors: null,
      employeeCode: null,
      email: null,
      staff: null,
    };
  },
  computed: {
    getAvatar() {
      return this.staff.avatar_url ?? "https://placekitten.com/300/300";
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const checker = new LiffChecker(this.$route.params.org_code);
      this.lineData = await checker.getLineData();
      this.initData = checker.initData;

      let response = await axios.get(
        process.env.VUE_APP_API_BASE_URL +
          "/general/organizations/" +
          this.$route.params.org_code
      );

      if (response.data.data) {
        this.organization = response.data.data;
      } else {
        alert("讀取資料錯誤");
        liff.closeWindow();
      }

      // 檢查此 line_id 是否已經綁定過
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
          `/${this.$route.params.org_code}/liff/sales/get-staff?line_id=${this.lineData.profile.userId}`
        )

        if (response.data.data) {
          this.staff = response.data.data;
        }
      } catch (error) {
        // 找不到此業務，繼續流程
      }

      this.displayLoading = false;
    },
    cancel() {
      let oaId = this.initData.line_oa_id;
      if (oaId) {
        window.location.href = `https://line.me/R/ti/p/@${oaId}`
      } else {
        liff.closeWindow();
      }
      return;
    },
    async submit() {
      let _this = this;
      _this.buttonLoading = true;

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/staff_active_chailease";

      await axios
        .post(url, {
          line_id: _this.lineData.profile.userId,
          line_access_token: _this.lineData.accessToken,
          employee_code: _this.employeeCode,
          email: (_this.email) ? _this.email + "@chailease.com.tw" : null,
        })
        .then((response) => {
          if (response.data.status === "success") {
            _this.staff = response.data.data.staff;
            return;
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.status);
          if (error.response.status == 422) {
            _this.validationErrors = error.response.data.message;
            console.log(_this.validationErrors);
          }
          if (
            error.response.data.status === "failed" &&
            error.response.data.message
          ) {
            this.$swal.fire({
              type: "warning",
              text: error.response.data.message
            });
          }
          _this.buttonLoading = false;
        });
    },
  },
};
</script>

<style>
.email-input-extra {
  padding-left: 10px;
  overflow: hidden;
  font-size: 12px;
  padding-top: 14px;
}

.flex {
  display: flex;
}
</style>
